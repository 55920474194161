import React, { type MutableRefObject } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
	Swiper,
	type TSwiperProps,
	type TSwiperRefActions,
	type TSwiperSlideChangedCbProps,
} from '@headout/espeon/components';
import { css, cx } from '@headout/pixie/css';

import CollectionCard from 'Components/desktop/collectionCard';

import {
	getCollectionCardIdsByCategoryId,
	getCollectionCardIdsByCityCode,
	getCollectionCardIdsByPersonaId,
	getCollectionCardIdsBySubcategoryId,
	getSimilarCollectionCards,
} from 'Utils/stateUtils';

import { TOP_ATTRACTIONS_COUNT } from 'Constants/constants';

interface ITopAttractionCarouselElement {
	currentCollectionId: string;
	isCategoryPage?: boolean;
	showPrice?: boolean;
	collectionIds: string[];
	showAllCards?: boolean;
	onSlideChanged: (props: TSwiperSlideChangedCbProps) => void;
	attractionsCarouselRef: MutableRefObject<TSwiperRefActions>;
	attractionsCountLimit?: number;
	parentIdentifier?: string;
	useSimilarCollections?: boolean;
}

const topAttractionsCarouselWrapperStyles = css({
	width: '[76.5rem]',
	marginLeft: '[-0.75rem]',
});

const TopAttractionCarouselElement = ({
	currentCollectionId,
	isCategoryPage,
	showPrice,
	collectionIds = [],
	showAllCards,
	onSlideChanged,
	attractionsCarouselRef,
	attractionsCountLimit,
	parentIdentifier,
	useSimilarCollections = false,
}: ITopAttractionCarouselElement) => {
	const isCardsArrowsRequired = collectionIds.length > 6;
	let collectionCards = collectionIds
		.filter((collectionId: string) => currentCollectionId !== collectionId)
		.map((collectionId, index) => (
			<CollectionCard
				key={collectionId}
				collectionNamePrefix={isCategoryPage && index + 1}
				id={collectionId}
				showPrice={showPrice}
				index={index}
				parentIdentifier={parentIdentifier}
				useV2CollectionFormat={useSimilarCollections}
			/>
		));

	if (!showAllCards) {
		collectionCards = collectionCards.slice(
			0,
			attractionsCountLimit ?? TOP_ATTRACTIONS_COUNT,
		);
	}

	const hideArrows = !!attractionsCarouselRef && !!onSlideChanged;
	const swiperSettings: Omit<TSwiperProps, 'children'> = {
		slidesToShow: 6,
		slidesToScrollBy: 6,
		swiperRef: attractionsCarouselRef,
		nextPrevControls: hideArrows ? 'hide' : 'show',
		onSlideChanged: onSlideChanged,
	};

	const containerClass = cx(
		topAttractionsCarouselWrapperStyles,
		'horizontal-scroll-wrapper',
		'cities-list-v2-scroll-wrapper',
		!isCardsArrowsRequired && 'no-arrow-button-city-search',
	);

	return (
		<div className={containerClass}>
			<Swiper {...swiperSettings}>{collectionCards}</Swiper>
		</div>
	);
};

const mapStateToProps = (state: any, ownProps: any) => {
	const {
		categoryId,
		subCategoryId,
		cityCode,
		personaId,
		useSimilarCollections,
		currentCollectionId,
	} = ownProps;
	let collectionIds;

	if (subCategoryId) {
		collectionIds = getCollectionCardIdsBySubcategoryId(
			state,
			subCategoryId,
		);
	} else if (categoryId) {
		collectionIds = getCollectionCardIdsByCategoryId(state, categoryId);
	} else if (personaId) {
		collectionIds = getCollectionCardIdsByPersonaId(
			state,
			cityCode,
			personaId,
		);
	} else if (useSimilarCollections && currentCollectionId) {
		collectionIds = getSimilarCollectionCards(state, currentCollectionId);
	} else {
		collectionIds = getCollectionCardIdsByCityCode(state, cityCode);
	}

	return { collectionIds };
};

const TopAttractionCarousel = connect(mapStateToProps)(
	TopAttractionCarouselElement,
);

(TopAttractionCarousel as any).propTypes = {
	renderImagesInServer: PropTypes.bool,
	showAllCards: PropTypes.bool,
	noOfCards: PropTypes.number,
	showAlphabetically: PropTypes.bool,
	parentIdentifier: PropTypes.string,
};

(TopAttractionCarousel as any).defaultProps = {
	renderImagesInServer: false,
	showAllCards: false,
	showPrice: true,
};

export default TopAttractionCarousel;
