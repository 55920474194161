// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

/* Styled Components */
export const CollectionContainerWrapper = styled.div`
	display: flex;
	margin: 0.75rem;
	cursor: pointer;
	position: relative;
	top: 0;

	&.collection-card {
		font-size: 0.625rem;
		line-height: 0.625rem;
	}

	&:hover {
		top: -0.1875rem;
		transition: top 0.2s ease-in-out 0s;
	}
`;

export const CollectionCardContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ImageContainer = styled.div`
	width: 11.25rem;
	height: 15rem;
	border-radius: 0.25rem;

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
		border-radius: 0.25rem;
	}
`;

export const CollectionIndex = styled.div`
	position: absolute;
	top: 0.5rem;
	left: 0.5rem;
	width: 1.5rem;
	height: 1.5rem;
	background: ${colors.GREY_DS.G8};
	border: 1px solid ${colors.GREY_DS.G5};
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${colors.GREY_DS.G2};
	${getFontDetailsByLabel(TYPE_LABELS.SUBHEADING_REGULAR)};
`;

export const CollectionName = styled.div`
	margin-top: 0.5rem;
	width: 11.25rem;

	.collection-name {
		color: ${colors.GREY['44']};
		${getFontDetailsByLabel(TYPE_LABELS.HEADING_PRODUCT_CARD)};
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
	}
`;

export const CollectionPrice = styled.div`
	margin-top: 0.25rem;

	.collection-price {
		color: ${colors.GREY['66']};
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_REGULAR)};
	}
`;
