import { useRef } from 'react';
import { connect } from 'react-redux';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';
import type { TSwiperRefActions } from '@headout/espeon/components';

import { SectionHeaderRightContainer } from 'Containers/desktop/citiesFeedPage';

import Conditional from 'Components/common/conditional';
import FeedSectionHeader from 'Components/common/feedSectionHeader';
import type { CarouselRefActions } from 'Components/common/lateralCarousel';
import NavigationButtons from 'Components/desktop/navigationButtons';
import TopAttractionCarousel from 'Components/desktop/topAttractionCarousel';
import ViewAllLink from 'Components/desktop/viewAllLink';

import { useSwiperArrows } from 'Hooks/useSwiperArrows';
import { trackEvent } from 'Utils/analytics';
import { getAllPlacesToVisitURL } from 'Utils/collectionsUtils';
import { createSanitisedIdentifer } from 'Utils/gen';
import {
	getCollectionCardIdsByCityCode,
	getCollectionCardIdsByPersonaId,
	getSimilarCollectionCards,
} from 'Utils/stateUtils';
import { capitalizeOnlyFirstLetter } from 'Utils/stringUtils';

import { ANALYTICS_EVENTS, ANALYTICS_PROPERTIES } from 'Constants/analytics';
import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

/* Styled Components */
export const TopAttractionSectionWrapper = styled.div<{
	$hasTransparentBackground: boolean;
}>`
	width: 100%;
	background: ${({ $hasTransparentBackground }) =>
		$hasTransparentBackground ? 'transparent' : `${colors.GREY['F8']}`};

	.view-all-link-text {
		color: ${colors.GREY['44']};
		${getFontDetailsByLabel(TYPE_LABELS.BUTTON_SMALL)};
	}
`;

const SectionContainer = styled.div`
	max-width: 75rem;
	min-width: 62.5rem;
	margin: 0 auto;
	padding: 2.625rem 0 1.25rem;
	display: flex;
	flex-direction: column;
	width: 100%;

	.feed-section-header-title {
		color: ${colors.GREY['44']};
		${getFontDetailsByLabel(TYPE_LABELS.HEADING_LARGE)};
	}
`;

type TopAttractionSectionProps = {
	cityName?: string;
	cityCode?: string;
	personaId?: number;
	lang?: string;
	isGlobalHomePage?: boolean;
	collectionIds?: any;
	currentCollectionId?: any;
	onScrollIntoView?: any;
	hasTransparentBackground?: boolean;
	showCityNameInHeading?: boolean;
	attractionsCountLimit?: number;
	useSimilarCollections?: boolean;
};

const TopAttractionSection = ({
	cityCode,
	cityName,
	personaId,
	lang,
	isGlobalHomePage,
	collectionIds,
	currentCollectionId,
	onScrollIntoView,
	hasTransparentBackground = false,
	showCityNameInHeading = true,
	attractionsCountLimit,
	useSimilarCollections = false,
}: TopAttractionSectionProps) => {
	const attractionsCarouselRef = useRef<TSwiperRefActions>(null);
	const { showLeftArrow, showRightArrow, onSlideChanged } = useSwiperArrows();
	const updatedCollectionIds = (collectionIds || []).filter(
		(collectionId: any) => collectionId !== currentCollectionId,
	);
	const getPageHeading = () => {
		switch (true) {
			case isGlobalHomePage || !cityCode:
				return strings.TOP_ATTRACTION.GLOBAL;
			case useSimilarCollections:
				return strings.formatString(
					strings.TOP_ATTRACTION.SIMILAR_COLLECTION,
					cityName,
				);
			case showCityNameInHeading:
				return strings.formatString(
					strings.TOP_ATTRACTION.CITY,
					cityName,
				);
			default:
				return strings.TOP_ATTRACTION.NO_CITY;
		}
	};
	const pageHeading = getPageHeading();

	const sectionIdentifier = createSanitisedIdentifer(pageHeading);

	const handlePrev = () => {
		attractionsCarouselRef.current?.prevSlide();
		trackSwiperArrowClick('backward');
	};

	const handleNext = () => {
		attractionsCarouselRef.current?.nextSlide();
		trackSwiperArrowClick('forward');
	};

	const trackSwiperArrowClick = (direction: 'forward' | 'backward') => {
		trackEvent({
			eventName: ANALYTICS_EVENTS.CAROUSEL.CHEVRON_CLICKED,
			[ANALYTICS_PROPERTIES.DIRECTION]:
				capitalizeOnlyFirstLetter(direction),
			[ANALYTICS_PROPERTIES.SECTION]: pageHeading,
		});
	};

	return (
		<Conditional if={updatedCollectionIds.length > 0}>
			<TopAttractionSectionWrapper
				className='top-attracions-section-wrapper'
				$hasTransparentBackground={hasTransparentBackground}
			>
				<SectionContainer className='top-attracions-section-container'>
					<FeedSectionHeader
						title={pageHeading}
						onScrollIntoView={onScrollIntoView}
						headingType={'h2'}
						newDesignSystem
					>
						<Conditional
							if={(updatedCollectionIds || []).length > 6}
						>
							<SectionHeaderRightContainer>
								<Conditional if={!useSimilarCollections}>
									<ViewAllLink
										to={
											isGlobalHomePage
												? `${
														lang && lang !== 'en'
															? `/${lang}`
															: ''
												  }/collections/`
												: getAllPlacesToVisitURL({
														cityCode,
														lang,
												  })
										}
										text={strings.CMN_SEE_ALL}
									/>
								</Conditional>
								<NavigationButtons
									showLeftArrow={showLeftArrow}
									showRightArrow={showRightArrow}
									prevSlide={handlePrev}
									nextSlide={handleNext}
								/>
							</SectionHeaderRightContainer>
						</Conditional>
					</FeedSectionHeader>
					<TopAttractionCarousel
						lang={lang}
						cityCode={cityCode}
						personaId={personaId}
						currentCollectionId={currentCollectionId}
						showAllCards={isGlobalHomePage}
						onSlideChanged={onSlideChanged}
						attractionsCarouselRef={attractionsCarouselRef}
						attractionsCountLimit={attractionsCountLimit}
						parentIdentifier={sectionIdentifier}
						useSimilarCollections={useSimilarCollections}
					/>
				</SectionContainer>
			</TopAttractionSectionWrapper>
		</Conditional>
	);
};

const mapStateToProps = (state: any, ownProps: TopAttractionSectionProps) => {
	const { useSimilarCollections, currentCollectionId, cityCode, personaId } =
		ownProps;
	let collectionIds;

	if (personaId && cityCode) {
		collectionIds = getCollectionCardIdsByPersonaId(
			state,
			cityCode,
			personaId,
		);
	} else if (useSimilarCollections && currentCollectionId) {
		collectionIds = getSimilarCollectionCards(state, currentCollectionId);
	} else {
		collectionIds = getCollectionCardIdsByCityCode(state, cityCode);
	}

	return { collectionIds };
};

export default connect(mapStateToProps)(TopAttractionSection);
