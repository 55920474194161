import { connect } from 'react-redux';
import Link from 'next/link';
import { withRouter } from 'next/router';

import Conditional from 'Components/common/conditional';
import Image from 'Components/common/image';
import LSpan from 'Components/common/localizedTags/localizedSpan';
import {
	CollectionCardContainer,
	CollectionContainerWrapper,
	CollectionIndex,
	CollectionName,
	CollectionPrice,
	ImageContainer,
} from 'Components/desktop/collectionCard/styles';
import {
	getCollectionCardDetails,
	getCollectionCardDetailsV2,
} from 'Components/desktop/collectionCard/utils';

import { trackEvent } from 'Utils/analytics';
import {
	getCollectionCardById,
	getCollectionCardByIdV2,
	getCurrenciesMap,
	getCurrentCityCode,
} from 'Utils/stateUtils';
import { getHumanReadableStringFromSanitizedIdentifier } from 'Utils/stringUtils';

import { ANALYTICS_EVENTS, ANALYTICS_PROPERTIES } from 'Constants/analytics';

const CollectionCard = ({
	collectionCardInfo,
	lang,
	id,
	currentCityCode,
	currenciesMap,
	collectionNamePrefix,
	showPrice = true,
	isSearch,
	onCardClick,
	appendCityCode = true,
	index,
	parentIdentifier,
	useV2CollectionFormat = false,
}: any) => {
	const { collectionUrlAsPath, subHeading, imageURL } = (
		useV2CollectionFormat
			? getCollectionCardDetailsV2
			: getCollectionCardDetails
	)({
		collectionDetails: collectionCardInfo,
		lang,
		currentCityCode,
		appendCityCode,
		isSearch,
		currenciesMap,
	});

	const { name, displayName } = collectionCardInfo;

	if (!collectionUrlAsPath) return null;

	const onClick = () => {
		window.open(collectionUrlAsPath, '_blank');
		trackEvent({
			eventName: ANALYTICS_EVENTS.COLLECTION_CARD_CLICKED,
			[ANALYTICS_PROPERTIES.COLLECTION_ID]: id,
			[ANALYTICS_PROPERTIES.COLLECTION_NAME]: name,
			[ANALYTICS_PROPERTIES.CARD_TYPE]: 'Standard Card',
			...(index !== undefined && {
				[ANALYTICS_PROPERTIES.RANKING]: index + 1,
			}),
			...(parentIdentifier
				? {
						[ANALYTICS_PROPERTIES.SECTION]:
							getHumanReadableStringFromSanitizedIdentifier(
								parentIdentifier,
							),
				  }
				: {}),
		});
		onCardClick?.();
	};

	return (
		<CollectionContainerWrapper
			onClick={onClick}
			className={'collection-card'}
			data-qa-marker='qaid-collection-card'
		>
			<CollectionCardContainer>
				<ImageContainer>
					<Image
						src={imageURL}
						width={180}
						height={240}
						alt={displayName}
						priority={false}
					/>
				</ImageContainer>

				<Conditional if={collectionNamePrefix}>
					<CollectionIndex>
						<span>{collectionNamePrefix}</span>
					</CollectionIndex>
				</Conditional>
				<CollectionName>
					<Link
						href={collectionUrlAsPath}
						aria-label={displayName}
						target='_blank'
					>
						<LSpan
							className='collection-name'
							onClick={e => e.stopPropagation()}
						>
							{displayName}
						</LSpan>
					</Link>
				</CollectionName>

				<Conditional if={showPrice}>
					<CollectionPrice>
						<LSpan className='collection-price'>{subHeading}</LSpan>
					</CollectionPrice>
				</Conditional>
			</CollectionCardContainer>
		</CollectionContainerWrapper>
	);
};

const mapStateToProps = (state: any, ownProps: any) => {
	const {
		id,
		router: {
			query: { lang = 'en' },
		},
		useV2CollectionFormat = false,
	} = ownProps;
	return {
		collectionCardInfo: useV2CollectionFormat
			? getCollectionCardByIdV2(state, id)
			: getCollectionCardById(state, id),
		currentCityCode: getCurrentCityCode(state),
		currenciesMap: getCurrenciesMap(state),
		lang,
	};
};

const mapSearchStateToProps = (state: any, ownProps: any) => {
	const {
		router: {
			query: { lang = 'en' },
		},
	} = ownProps;

	return {
		currentCityCode: getCurrentCityCode(state),
		currenciesMap: getCurrenciesMap(state),
		lang,
	};
};

export default withRouter(connect(mapStateToProps)(CollectionCard));

const SearchCollectionCard = withRouter(
	connect(mapSearchStateToProps)(CollectionCard),
);
export { SearchCollectionCard };
